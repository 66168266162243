.nav {
  font-size: 2em;
  line-height: 2;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}
.nav__link {
  padding: 1em;
}
.nav__link:before {
  content: '❦';
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
  margin-right: 0.5em;
}
.nav__link:after {
  content: '❦';
  margin-left: 0.5em;
  visibility: hidden;
  opacity: 0;
}
.nav__link--active {
  cursor: default;
  color: #ccc;
  font-weight: bold;
}
.nav__link--active:hover,
.nav__link--active:focus {
  text-decoration: none;
}
.nav__link--active:before {
  opacity: 1;
  visibility: visible;
}
/*# sourceMappingURL=src/components/nav.css.map */