@import 'normalize.css/normalize.css';
:global html {
  font-family: "futura-pt", sans-serif;
}
:global html.wf-loading {
  color: transparent;
}
:global html.wf-loading * {
  color: transparent !important;
  text-shadow: none !important;
}
:global ::selection {
  background: #0187c4;
  color: #b1e6ff;
}
:global a {
  color: #7a7a7a;
  text-decoration: none;
}
:global a:hover,
:global a:focus {
  text-decoration: underline;
}
:global body {
  min-height: 100vh;
  background-color: #00020f;
  color: #ccc;
}
/*# sourceMappingURL=src/app.css.map */