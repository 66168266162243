.main {
  font-family: "adobe-garamond-pro", serif;
  padding: 0 2.5em;
  font-size: 1.5em;
  line-height: 1.333;
  position: relative;
  transition: color 3s;
}
.main::before {
  position: absolute;
  z-index: -1;
  content: '';
  color: rgba(255,255,255,0.25);
  transition: color 3s;
  mask-image: linear-gradient(to bottom, currentColor, #fff 20%, currentColor 40%);
  background-image: url("assets/images/arp299.jpg");
  background-size: cover;
  background-position: bottom center;
  top: -128px;
  bottom: -5em;
  left: 0;
  right: 0;
}
.main:hover {
  color: #e6e6e6;
  text-shadow: 0px 0px 4px rgba(0,2,15,0.75);
}
.main:hover::before {
  color: rgba(255,255,255,0.5);
}
.h1 {
  display: none;
}
.h2 {
  margin: 0;
  color: #e6e6e6;
  text-shadow: 0px 0px 4px rgba(0,2,15,0.75);
  padding: 0.83em 0;
  font-style: italic;
  font-weight: bold;
  text-align: center;
}
.h2::before {
  content: '☙ ';
}
.h2::after {
  content: ' ❧';
}
.credit {
  font-size: 0.75em;
  padding: 2em 10% 0;
  text-align: center;
}
.credit h2 {
  color: rgba(204,204,204,0.8);
}
.credit h2 a {
  color: inherit;
}
.credit h2::before {
  content: 'background image:';
  font-style: italic;
  font-weight: normal;
  font-size: 0.75em;
  display: block;
}
.credit blockquote {
  font-size: 1.25em;
  margin: 0;
}
.credit blockquote::before,
.credit blockquote::after {
  display: inline-block;
  position: relative;
  font-size: 2.5em;
  font-weight: bold;
  color: rgba(204,204,204,0.5);
}
:global(html.wf-loading) .credit blockquote::before,
:global(html.wf-loading) .credit blockquote::after {
  color: transparent !important;
}
.credit blockquote::before {
  content: '“';
  margin-right: -2em;
  vertical-align: top;
  right: 2em;
}
.credit blockquote::after {
  content: '”';
  margin-left: -2em;
  left: 2em;
  vertical-align: bottom;
}
.credit blockquote > p {
  display: inline-block;
}
/*# sourceMappingURL=src/components/about.css.map */