.wrapper {
  background-color: transparent;
  transition: background-color 0.5s;
  position: relative;
}
.wrapper::before {
  z-index: 0;
  mask-image: url("assets/images/black-thread-light.png");
  background-image: url("assets/images/splash.jpg");
  background-size: cover;
  background-position: center;
  mask-type: alpha;
  content: '';
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.wrapper::before :global {
  animation: 10s linear infinite translate-mask-position;
}
.wrapper {
  background-color: transparent;
}
.wrapper.mask {
  background-color: rgba(255,255,255,0.15);
}
.wrapper.mask:hover {
  background-color: rgba(255,255,255,0.05);
}
.wrapper.mask::before {
  opacity: 1;
}
.wrapper.inactive:hover {
  background-color: rgba(255,255,255,0.8);
}
.wrapper.inactive:hover::before {
  opacity: 1;
}
.wrapper.inactive {
  cursor: pointer;
}
.main {
  background-image: url("assets/images/splash.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: inherit;
  line-height: 1.5;
  font-size: 1.5em;
  position: relative;
  z-index: 0;
  transition: padding 1s;
  padding: 2em 0;
}
:global(.wf-active) .main.inactive:hover,
:global(.wf-active) .main.mask {
  -webkit-background-clip: text;
}
:global(.wf-active) .main.inactive:hover h1,
:global(.wf-active) .main.mask h1 {
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 0 rgba(21,181,254,0.25);
}
:global(.wf-active) .main.inactive:hover::after,
:global(.wf-active) .main.mask::after {
  opacity: 1;
}
.main h1 {
  transition: color 1s;
  color: #ddd;
  font-size: 10vw;
  margin: 0;
}
.main h2 {
  font-size: 4vw;
  font-style: italic;
}
.main.active {
  padding: 80vh 0 2em;
}
.main.active::before {
  opacity: 1;
}
.details {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s, opacity 0.5s;
}
.details.active {
  max-height: 500px;
  visibility: visible;
  opacity: 1;
  height: auto;
}
.credit {
  font-size: 0.75em;
  padding: 0 10%;
}
.credit p {
  display: inline;
}
@-moz-keyframes :global(translate-mask-position) {
  0% {
    mask-position: 0% 0%;
  }
  100% {
    mask-position: 0% 100%;
  }
}
@-webkit-keyframes :global(translate-mask-position) {
  0% {
    mask-position: 0% 0%;
  }
  100% {
    mask-position: 0% 100%;
  }
}
@-o-keyframes :global(translate-mask-position) {
  0% {
    mask-position: 0% 0%;
  }
  100% {
    mask-position: 0% 100%;
  }
}
@keyframes :global(translate-mask-position) {
  0% {
    mask-position: 0% 0%;
  }
  100% {
    mask-position: 0% 100%;
  }
}
/*# sourceMappingURL=src/components/splash.css.map */