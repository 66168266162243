.main {
  font-size: 80%;
  font-family: monospace;
  white-space: pre-wrap;
  margin: 1em auto;
  color: #00020f;
  display: inline-block;
  background-color: rgba(122,122,122,0.5);
  padding: 1em;
  border-radius: 0.5em;
  text-shadow: 0px 0px 4px rgba(122,122,122,0.75);
}
/*# sourceMappingURL=src/components/commit-message.css.map */