@import '../_vars.styl'

@keyframes :global(translate-mask-position) {
	0% {
		mask-position 0% 0%
	}

	100% {
		mask-position 0% 100%
	}
}

.wrapper
	background-color transparent
	transition background-color 0.5s
	position relative

	&::before
		z-index 0
		mask-image url('assets/images/black-thread-light.png')
		background-image url('assets/images/splash.jpg')
		background-size cover
		background-position center
		mask-type alpha
		content ''
		opacity 0
		transition opacity 0.5s
		position absolute
		top 0
		bottom 0
		left 0
		right 0
		width 100%
		:global
			animation 10s linear infinite translate-mask-position

.wrapper
	background-color transparent
	&.mask
		background-color alpha(white, 15%)
		&:hover
			background-color alpha(white, 5%)	
		&::before
			opacity 1

	&.inactive:hover
		background-color alpha(white, 80%)
		&::before
			opacity 1
			
	&.inactive
		cursor pointer

.main
	background-image url('assets/images/splash.jpg')
	background-size cover
	background-repeat no-repeat
	background-position center
	text-align center
	color inherit

	line-height 1.5
	font-size 1.5em

	position relative
	z-index 0
	
	transition padding 1s
	padding 2em 0

	:global(.wf-active) &
		&.inactive:hover, &.mask
			-webkit-background-clip text
			h1
				-webkit-text-fill-color transparent
				text-shadow 0 0 0 alpha(lighten(splash, 25%), 25%)
			&::after
				opacity 1

	h1
		transition color 1s
		color #ddd
		font-size 10vw
		margin 0

	h2
		font-size 4vw
		font-style italic

.main.active
	padding 80vh 0 2em
	&::before
		opacity 1

.details
	max-height 0
	visibility hidden
	opacity 0
	overflow hidden
	transition max-height 0.5s, opacity 0.5s
	
	&.active
		max-height 500px
		visibility visible
		opacity 1
		height auto

.credit
	font-size .75em
	padding 0 10%
	p
		display inline
