@import '_vars.styl'

:global
	@import 'normalize.css/normalize.css'
	html
		font-family "futura-pt", sans-serif
		&.wf-loading
			color transparent
			*
				color transparent !important
				text-shadow none !important

	::selection
		background selection-color
		color lighten(selection-color, 75%)

	a
		color accentColor
		text-decoration none
		&:hover, &:focus
			text-decoration underline


	body
		min-height 100vh
		background-color splashDark
		color mainColor
