@import '../_vars.styl'

.main
	font-size 80%
	font-family monospace
	white-space pre-wrap
	margin 1em auto
	color splashDark
	display inline-block
	background-color alpha(accentColor, 50%)
	padding 1em
	border-radius 0.5em
	text-shadow 0px 0px 4px alpha(accentColor, 75%)
