@import '../_vars.styl'

.nav
	font-size 2em
	line-height 2
	display flex
	flex-flow row wrap
	justify-content space-around
	align-items center

	&__link
		padding 1em
		&:before
			content '❦'
			opacity 0
			visibility hidden
			transition opacity 0.5s
			margin-right 0.5em
		&:after
			content '❦'
			margin-left 0.5em
			visibility hidden
			opacity 0
		&--active
			cursor default
			color mainColor
			font-weight bold
			&:hover, &:focus
				text-decoration none
			&:before
				opacity 1
				visibility visible
